<template>
<div>
    <div class="aa-sec-noticias">
        <span class="aa-text-h1 text-start d-block text-uppercase p-1">
            Noticias
        </span>
        <span class="aa-sec-noticias-line"></span>
    </div> 

    <Destacado :datos="primeraNoticia" v-if="primeraNoticia" :url="'noticia'" />

    <div class="aa-sec-noticias">
        <span class="aa-text-h1 text-start d-block text-uppercase p-1">
            Archivo
        </span>
        <span class="aa-sec-noticias-line"></span>
    </div> 

    <div class='scrolling-component mb-1' ref='scrollComponent'>
		<NoticiaGrid v-for='noticia in noticiasList' :datos="noticia" :key="noticia.id" :url="'noticia'" class="fade-in" />
	</div>

    <span v-if="isLoading"> Cargando </span>
</div>
</template>

<script>
import useNoticias from '../composables/useNoticias'
import { defineAsyncComponent, onMounted, onUnmounted } from "vue";

export default {
components: { 
    NoticiaGrid: defineAsyncComponent( () => import('../components/Prensa/NoticiaGrid.vue') ), 
    Destacado: defineAsyncComponent( () => import('../components/Prensa/Destacado.vue') ) 
},
setup(){
    const {cargaNoticias, cargaMas, primeraNoticia, noticiasList, isLoading, scrollComponent } = useNoticias()
    
    cargaNoticias()
    
    onMounted(() => {
        window.addEventListener("scroll", handleScroll)
    })

    onUnmounted(() => {
        window.removeEventListener("scroll", handleScroll)
    })
    

    const handleScroll = () => {
        let element = scrollComponent.value;
        if (element.getBoundingClientRect().bottom < window.innerHeight) {
            cargaMas()
        }
    }

    return {
        primeraNoticia,
        noticiasList, 
        isLoading,
        scrollComponent,
        cargaMas
    }
}

}
</script>

<style lang="sass" scoped>
.aa-sec-noticias
    position: relative
    margin-top: 96px
    $root:&
    &-line
        background-color: #86e4b9
        display: inline-block
        height: 25px
        left: 0
        position: absolute
        top: 34px
        width: 163px
        z-index: -1

</style>